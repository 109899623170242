import axios from 'axios';

window.initMap = async function () {
    const europe_center = { lat: 27.790738, lng: -15.715211 };
    const map = new google.maps.Map(document.getElementById("map"), {
        zoom: 15,
        center: europe_center,
    });

    const hotels =
        [{
            name: 'Gran Amadores',
            link: '/hotels/gran-amadores',
            tel: '+34 928 153 099',
            position: {
                lat: 27.7910449,
                lng: -15.720638
            },
            address: 'C. Islas Afortunadas, 3, 35130 Mogán, Las Palmas, España'
        },
        {
            name: 'Punta del Rey',
            link: '/hotels/punta-del-rey',
            tel: '+34 928 560 081',
            position: {
                lat: 27.7842316,
                lng: -15.716581
            },
            address: 'Av. de Mogán, 42, 35100 Puerto Rico de Gran Canaria, Las Palmas, España'
        },
        {
            name: 'Porlamar',
            link: '/hotels/porlamar',
            tel: '+34 928 561 357',
            position: {
                lat: 27.7845397,
                lng: -15.7084514
            },
            address: 'C. el Gavilán, 12, 35139 Mogán, Las Palmas, España'
        },
        {
            name: 'Leticia del Mar',
            link: '/hotels/leticia-del-mar',
            tel: '+34 689 116 011',
            position: {
                lat: 27.7884266,
                lng: -15.7114925
            },
            address: 'Av. de Mogán, 3, 5, 35130 Puerto Rico de Gran Canaria, Las Palmas, España'
        },
        {
            name: 'Parque Raquel',
            link: '/hotels/parque-raquel',
            tel: '+34 689 116 011',
            position: {
                lat: 27.7918186,
                lng: -15.7091414
            },
            address: 'Av. las Palmeras, 13, 35130 Puerto Rico de Gran Canaria, Las Palmas, España'
        },
        {
            name: 'Nancy Penthouses',
            link: '/hotels/nancy-penthouses',
            tel: '+34 689 116 011',
            position: {
                lat: 28.13550021762851,
                lng: -15.434068255341534
            },
             
            address: 'C. Juan Manuel Durán González, 30, 35007 Las Palmas, España'
        }
        ];

    for (let hotel in hotels) {
        const infowindow = new google.maps.InfoWindow();
        const marker = new google.maps.Marker({
            position: hotels[hotel].position,
            map,
            title: hotels[hotel].name,
        });

        infowindow.setContent(
            `<h3><a href = "${hotels[hotel].link}">${hotels[hotel].name}</a></h3>
            Dirección: <p><b>${hotels[hotel].address}</b></p>
            Teléfono: <p><b>${hotels[hotel].tel}</b></p>`,
        );

        infowindow.setPosition(hotels[hotel].position);

        marker.addListener("click", () => {
            infowindow.open({
                anchor: marker,
                map,
                shouldFocus: false,
            });
        });
    }
}
